import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";
import { v4 as uuid } from "uuid";

export const specificationsApi = createApi({
  baseQuery: APIBaseQuery,
  reducerPath: "specificationsAPi",
  keepUnusedDataFor: 1,
  tagTypes: ["Specifications"],
  endpoints: (build) => ({
    getSpecifications: build.query({
      query() {
        return {
          url: "specifications?deleted=false",
        };
      },
      providesTags: ["Specifications"],
    }),
    getSpecificationById: build.query({
      query(id) {
        return {
          url: `specifications/${id}`,
        };
      },
    }),
    createSpecification: build.mutation({
      query(data) {
        return {
          method: "POST",
          url: "specifications",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data,
        };
      },
      invalidatesTags: ["Specifications"],
    }),
    editSpecification: build.mutation({
      query(data) {
        return {
          method: "PUT",
          url: "specifications",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data,
        };
      },
      invalidatesTags: ["Specifications"],
    }),
    deleteSpecification: build.mutation({
      query(id) {
        return {
          method: "DELETE",
          url: `specifications/${id}`,
          //   headers: {
          //     "x-requestid": uuid(),
          //   },
        };
      },
      invalidatesTags: ["Specifications"],
    }),
  }),
});

export const {
  useGetSpecificationsQuery,
  useGetSpecificationByIdQuery,
  useCreateSpecificationMutation,
  useEditSpecificationMutation,
  useDeleteSpecificationMutation,
} = specificationsApi;
