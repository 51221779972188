import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCreateQuestionMutation } from "../../../../redux/api/Courses/coursesApi";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Reader from "../components/Reader";
import PlusIcon from "@material-ui/icons/Add";
import { Tooltip, Checkbox, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { useGetQuestionByIdQuery } from "../../../../redux/api/Courses/coursesApi";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import BlotFormatter from "quill-blot-formatter";

export default function CreateQuestion({
  show,
  onHide,
  questionId,
  refetch,
  totalCount,
}) {
  // TODO: find the best practise for registering BlotFormatter without getting an error on console.

  Quill.register("modules/blotFormatter", BlotFormatter);
  Quill.debug("error");

  const { token } = useSelector(({ user }) => ({ token: user.token }));
  const { data, isSuccess } = useGetQuestionByIdQuery(questionId);

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState([]);
  const [currentOrder, setCurrentOrder] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [questionVariants, setQuestionVariants] = useState([
    {
      variant: "",
      order: 1,
      isAnswer: true,
    },
  ]);
  const [openQuestionVariants, setOpenQuestionVariants] = useState([
    {
      variant: "",
      order: 1,
      isAnswer: true,
    },
  ]);

  const addVariant = () => {
    if (isOpen) {
      let index = openQuestionVariants.length - 1;
      let order = index === -1 ? 1 : openQuestionVariants[index].order + 1;
      let tempState = [
        ...openQuestionVariants,
        {
          variant: "",
          order,
          isAnswer: true,
        },
      ];
      setOpenQuestionVariants(tempState);
    } else {
      let index = questionVariants.length - 1;
      let order = index === -1 ? 1 : questionVariants[index].order + 1;
      let tempState = [
        ...questionVariants,
        {
          variant: "",
          order,
          isAnswer: false,
        },
      ];
      setQuestionVariants(tempState);
    }
  };

  const removeVariant = (index) => {
    if (isOpen) {
      let tempState = openQuestionVariants.filter(
        (item) => item.order !== index
      );
      setOpenQuestionVariants(tempState);
    } else {
      let tempState = questionVariants.filter((item) => item.order !== index);
      setQuestionVariants(tempState);
    }
  };

  const chooseAnswer = (ObjIndex) => {
    let correctAnswerIndex = questionVariants.findIndex(
      (item) => item.isAnswer === true
    );

    if (ObjIndex !== correctAnswerIndex) {
      let tempState = questionVariants.map((variant, idx) => {
        if (idx === ObjIndex) {
          return {
            ...variant,
            isAnswer: true,
          };
        } else {
          return {
            ...variant,
            isAnswer: false,
          };
        }
      });

      setQuestionVariants(tempState);
    }
  };

  const handleChangeVariant = (content, delta, source, editor, index) => {
    let tempState = [...questionVariants];
    tempState[index].variant = content;
    setQuestionVariants(tempState);
    window.MathJax.typesetPromise([
      document.getElementsByClassName("mathjax-container"),
    ]);
  };

  const handleChangeOpenVariant = (e, index) => {
    let tempState = [...openQuestionVariants];
    tempState[index].variant = e.target.value;
    setOpenQuestionVariants(tempState);
  };

  const handleTitle = (content, delta, source, editor) => {
    setTitle(content);
    window.MathJax.typesetPromise([
      document.getElementsByClassName("mathjax-container"),
    ]);
  };

  const handleChange = (e) => {
    setIsOpen((val) => !val);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const asset = {
      id: questionId,
      title: title,
      isOpen: isOpen,
      order: currentOrder,
      questionVariants: isOpen ? openQuestionVariants : questionVariants,
    };
    setIsLoading(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}questions`, asset, {
        headers: {
            // "x-requestid": uuid(),
            "Authorization": `Bearer ${token}`
        }
      })
      .then(() => {
        setIsLoading(false);
        refetch();
        onHide(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: "auto",
      display: "block",
      color: "#34a9fc",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (isSuccess) {
      const { title, isOpen, order, questionVariants } = data;

      setIsOpen(isOpen);
      setTitle(title);
      let tempOrder = [];
      for (let i = 1; i <= totalCount; i++) {
        tempOrder.push(i);
      }
      setOrder(tempOrder);
      setCurrentOrder(order);
      let tempQuestion = [];
      if (isOpen) {
        questionVariants.map((item, index) => {
          let obj = {
            variant: item.variant,
            order: item.order,
            isAnswer: item.isAnswer,
          };
          tempQuestion.push(obj);
        });
        setOpenQuestionVariants(tempQuestion);
      } else {
        questionVariants.map((item, index) => {
          let obj = {
            variant: item.variant,
            order: item.order,
            isAnswer: item.isAnswer,
          };
          tempQuestion.push(obj);
        });
        setQuestionVariants(tempQuestion);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (title) {
      window.MathJax.typesetPromise([
        document.getElementsByClassName("mathjax-container"),
      ]);
    }
  }, [title]);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{ list: [] }],
        [{ align: [] }],
        ["clean"],
        [{ color: [] }],
        ["image"],
      ],
    },
    blotFormatter: {},
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="create">Edit the question</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {isSuccess ? (
            <div className="col-12 pl-4 pr-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <InputLabel htmlFor="title">Title input</InputLabel>
                    <ReactQuill
                      modules={modules}
                      style={{ width: "100%", height: "100%" }}
                      theme="snow"
                      name="title"
                      value={title}
                      onChange={(content, delta, source, editor) =>
                        handleTitle(content, delta, source, editor)
                      }
                    />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <InputLabel htmlFor="title">Title output</InputLabel>

                    <Reader value={title} />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isOpen}
                        onChange={handleChange}
                        color="secondary"
                      />
                    }
                    label={isOpen ? "Question is open" : "Question is not open"}
                    className="ml-auto mb-0"
                  />
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  {isOpen && isSuccess
                    ? openQuestionVariants.map((variant, index) => (
                        <div
                          key={index}
                          className="w-100 pt-2 pb-3 d-flex flex-wrap"
                        >
                          <InputLabel htmlFor="variant">
                            Variant {variant.order} input
                          </InputLabel>
                          <div className="w-100">
                            <TextField
                              value={openQuestionVariants[index].variant}
                              onChange={(e) =>
                                handleChangeOpenVariant(e, index)
                              }
                            />
                          </div>
                          <Button
                            onClick={() => removeVariant(variant.order)}
                            variant="danger"
                            className="mt-2"
                          >
                            Remove
                          </Button>
                        </div>
                      ))
                    : isSuccess &&
                      questionVariants.map((variant, index) => (
                        <div
                          key={index}
                          className="w-100 pt-2 pb-3 d-flex flex-wrap"
                        >
                          <InputLabel htmlFor="title">
                            Variant {variant.order} input
                          </InputLabel>
                          <ReactQuill
                            modules={modules}
                            style={{ width: "100%", height: "100%" }}
                            value={questionVariants[index].variant}
                            onChange={(content, delta, source, editor) =>
                              handleChangeVariant(
                                content,
                                delta,
                                source,
                                editor,
                                index
                              )
                            }
                          />
                          <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                            <InputLabel htmlFor="title">
                              Variant {variant.order} output
                            </InputLabel>
                            <div
                              className="mathjax-container w-100"
                              dangerouslySetInnerHTML={{
                                __html: questionVariants[index].variant,
                              }}
                            ></div>
                          </div>
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <Button
                              onClick={() => removeVariant(variant.order)}
                              className="mt-2"
                              variant="danger"
                            >
                              Remove
                            </Button>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={questionVariants[index].isAnswer}
                                    onChange={() => chooseAnswer(index)}
                                  />
                                }
                                label="Is correct"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <Tooltip title="Add a variant">
                      <Button onClick={addVariant}>
                        <PlusIcon />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <FormControl className="w-100">
                    <InputLabel id="order">Order</InputLabel>
                    <Select
                      labelId="order"
                      id="order"
                      onChange={(e) => setCurrentOrder(e.target.value)}
                      value={currentOrder}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {order.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          ) : (
            <CircularProgress className={classes.progress} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
            }}
          >
            Close
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="info"
            className={`px-9`}
          >
            Update
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
