import React, { useRef, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import LinearProgress from "../components/LinearProgress";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useGetVideoByIdQuery } from "../../../../redux/api/Courses/coursesApi";
import { CircularProgress, InputLabel } from "@material-ui/core";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { v4 as uuid } from "uuid";
import axios from "axios";

export default function UpdateVideo({
  show,
  onHide,
  videoTitle,
  videoId,
  totalCount,
  itemId,
  refetch,
  contentId,
}) {
  const formRef = useRef(null);
  const { token } = useSelector(({ user }) => ({ token: user.token }));
  const { data, isLoading, isSuccess } = useGetVideoByIdQuery(videoId);
  const [file, setFile] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [fileName, setFileName] = useState("");
  const [percent, setPercent] = useState(0);
  const [order, setOrder] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const onUpdateForm = (values) => {
    const { title, description, order, youtubeUrl } = values;

    const asset = new FormData();
    asset.append("id", videoId);
    asset.append("order", order);
    asset.append("title", title);
    asset.append("description", description);
    youtubeUrl && asset.append("youtubeUrl", youtubeUrl);
    !youtubeUrl && asset.append("urlFile", file);
    setIsUpdating(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}videos`, asset, {
        headers: {
            // "x-requestid": uuid(),
            "Authorization": `Bearer ${token}`
        },
        onUploadProgress: ({ loaded, total }) => {
          const tempPercent = Math.round((loaded / total) * 100);
          if (percent < tempPercent) {
            setPercent(tempPercent);
          }
        },
      })
      .then(() => {
        setPercent(0);
        refetch();
        onHide(false);
        setIsUpdating(false);
      })
      .catch(() => {
        setPercent(0);
        setIsUpdating(false);
      });
  };

  const initialValues = {
    title: "",
    description: "",
    order: "",
    youtubeUrl: "",
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please enter a title."),
    description: Yup.string().required("Please enter a description."),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onUpdateForm(values);
    },
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const handleVideo = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    setFileName(file.name);
    reader.onloadend = () => {
      setVideoSrc(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (isSuccess) {
      const { title, description, order, youtubeUrl } = data;
      formik.setFieldValue("title", title);
      formik.setFieldValue("description", description);
      formik.setFieldValue("order", order);
      formik.setFieldValue("youtubeUrl", youtubeUrl);
    }
  }, [isLoading]);

  useEffect(() => {
    if (totalCount) {
      let arr = [];
      let count = totalCount + 1;
      for (let i = 1; i < count; i++) {
        arr.push(i);
      }
      setOrder(arr);
    }
  }, [totalCount]);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="create">Edit "{videoTitle}"</Modal.Title>
      </Modal.Header>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress style={{ color: "#34a9fc" }} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} ref={formRef}>
          <Modal.Body>
            <div className="col-12 pl-4 pr-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="title"
                      name="title"
                      label="Title"
                      placeholder="Title"
                      className="w-100"
                      error={getInputClasses("title")}
                      {...formik.getFieldProps("title")}
                      value={formik.values.title || ""}
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.title}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="description"
                      name="description"
                      label="Description"
                      placeholder="Description"
                      className="w-100"
                      error={getInputClasses("description")}
                      {...formik.getFieldProps("description")}
                      value={formik.values.description || ""}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.description}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <FormControl className="w-100">
                    <InputLabel id="order">Order</InputLabel>
                    <Select
                      {...formik.getFieldProps("order")}
                      labelId="order"
                      id="order"
                      error={getInputClasses("order")}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {order.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.order && formik.errors.order ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.order}</div>
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="youtubeUrl"
                      name="youtubeUrl"
                      label="Youtube url"
                      placeholder="Youtube url"
                      className="w-100"
                      error={getInputClasses("youtubeUrl")}
                      {...formik.getFieldProps("youtubeUrl")}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-column align-items-center flex-wrap">
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="video/*"
                        id="contained-button-file"
                        type="file"
                        name="UrlFile"
                        label="File {Path}"
                        placeholder="File Path"
                        className="w-100"
                        onChange={handleVideo}
                        hidden
                      />
                      <Button2
                        disabled={
                          isUpdating || Boolean(formik.values.youtubeUrl)
                        }
                        variant="contained"
                        component="span"
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Devices/Video-camera.svg"
                          )}
                          className="mr-2"
                        />
                        {file ? "File has been chosen" : "Choose the video"}
                      </Button2>
                    </label>
                    {fileName}
                    {isUpdating && <LinearProgress progress={percent} />}
                    {isUpdating && (
                      <span>
                        Please wait until the video loads. This may take a while
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => {
                onHide(false);
                formik.resetForm();
              }}
            >
              Close
            </Button>
            <Button
              disabled={isUpdating}
              type="submit"
              variant="info"
              className={`px-9`}
            >
              Update
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
}
