import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";
import { setUsers, setPermissions } from "../../features/Users/usersSlice";
import { v4 as uuid } from "uuid";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query(data) {
        const { skip, take, field, order, firstName, lastName, email } = data;
        return {
          url: `users?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${order}${
            firstName ? `&FirstName=${firstName}` : ""
          }${lastName ? `&LastName=${lastName}` : ""}${
            email ? `&Email=${email}` : ""
          }&deleted=false`,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setUsers(data.data));
        } catch (error) {
          console.log("error");
        }
      },
    }),
    createUser: builder.mutation({
      query(body) {
        return {
          url: "users",
          method: "POST",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: body,
        };
      },
      keepUnusedDataFor: 1,
    }),
    getUserById: builder.query({
      query(id) {
        return {
          url: `users/${id}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    getUsersPermissons: builder.query({
      query(data) {
        return {
          url: `/users/permissions`,
        };
      },

      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setPermissions(data));
        } catch (error) {}
      },
    }),
    updateUserPassword: builder.mutation({
      query(body) {
        return {
          url: "users/change-password",
          method: "PUT",
          data: body,
          // headers: {
          //     "x-requestid": uuid()
          // },
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    resetUserPassword: builder.mutation({
      query(body) {
        return {
          url: `users/reset-password`,
          method: "PUT",
          data: body,
          // headers: {
          //     "x-requestid": uuid()
          // }
        };
      },
    }),
    updateUsers: builder.mutation({
      query(body) {
        return {
          url: "users",
          method: "PUT",
          data: body,
          // headers: {
          //     "x-requestid": uuid()
          // },
        };
      },
      keepUnusedDataFor: 1,
    }),
    deleteUsers: builder.mutation({
      query(id) {
        return {
          url: `users/${id}`,
          method: "DELETE",
        };
      },
    }),
    getRoles: builder.query({
      query(arg) {
        return {
          url: "roles",
        };
      },
      transformResponse: (res) => res.data,
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useGetUserByIdQuery,
  useGetChooseUserQuery,
  useUpdateUsersMutation,
  useGetUsersPermissonsQuery,
  useUpdateUserPasswordMutation,
  useResetUserPasswordMutation,
  useDeleteUsersMutation,
  useGetRolesQuery,
} = usersApi;
